<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Latest Territory Virtual 
            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-primary.m-btn-icon(
                            v-if="checkFeatureByAcl('territory_virtual', 'create')"
                            to="/global-territory-virtual/create"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 New Territory Virtual

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"                        
                        type="text"
                        placeholder="Search here..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
                //- .field.mr-3( style="min-width: 180px;")
                //-     select.field-style()
                //-         option All Operator
                //-         option Operator 1
                //-         option Operator 2
                //-     .field-icon
                //-         IconSet(
                //-             type="chevron-down"
                //-         )

                //- .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                //-     .relative.field-style.w-full
                //-         date-range-picker(
                //-             v-if="dateRange.startDate && dateRange.endDate"
                //-             ref="picker"
                //-             v-model="dateRange"
                //-             @update="pickerUpdate($event)" 
                //-             :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                //-             :date-format="dateFormat" 
                //-             :autoApply="true"
                //-             opens="right"
                //-             :ranges="default_ranges"
                //-         )
                //-             div(
                //-                 slot="input" 
                //-                 slot-scope="picker") 
                //-                     | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                //-         .field-icon
                //-             IconSet(type="calendar")

                //- button.btn-primary.mr-3(
                //-     @click="filterList()"
                //- ) Filter

            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")

                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td
                                    //- router-link.link(:to="'/global-territory-virtual/'+data.id+'/detail'") {{ data.name ? data.name : '-' }}
                                    router-link.link(:to="{name: 'territory-virtual-detail', params: {id:data.id, data:encrypt(JSON.stringify({'name':data.name,'from_date':data.from_date,'to_date':data.to_date}))}}") {{ data.name ? data.name : '-' }}

                                td(v-if="data.target && data.target.length > 0")
                                    .list-target
                                        .list-target__avatar(v-for="target in data.target.slice(0, 9)")
                                            .hidden {{ target.target_name }} : {{ target.msisdn && target.msisdn.length > 0 ? target.msisdn.join(', ') : '-' }}
                                            figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="target.target_name")
                                                img.w-full.h-full(
                                                    :src="target.photo ? target.photo : getDefaultImage"
                                                    @error="getDefaultImage"
                                                )
                                        .list-target__more.w-12.h-12.overflow-hidden.rounded-full.bg-white.border-4.flex.items-center.justify-center.bg-white(v-if="data.target.length > 10")
                                            span.text-sm +{{ data.target.length - 10 }}
                                td(v-else) -

                                td
                                    span.text-xs {{ toString(data.cases) }}

                                td
                                    p {{ data.description ? (data.description.length > 50 ? data.description.substring(0, 80)+'...' : data.description) : '-' }}
                                td
                                    //- template(v-if="data.note")
                                    //-     .text-sm.note(@click="getNotes(true, data.id, data.name)") {{data.note?data.note.content:'no note'}}
                                    //- template(v-else)
                                    //-     button.link(@click="getNotes(true, data.id, data.name)") {{data.note?data.note.content:'Add a note'}}               
                                    .w-48
                                        template(
                                            v-if="!checkFeatureByAcl('territory_virtual', 'create') && data?.note == undefined"
                                        ) -
                                        button.link.text-sm.truncate.text-left(style="width: 240px;"
                                            v-else
                                            @click="getNotes(true, data.id, data.name)"
                                        ) {{data?.note&&data?.note?.content?data?.note?.content:'Add a note'}}
                                td
                                    p(v-if="data.created_at || data.created_by") {{ data.created_by.name?data.created_by.name:"-" }}
                                    p(v-else) -   
                            
                                td
                                    p(v-if="data.created_at || data.created_by") {{ data.created_at }} 
                                    p(v-else) -                                 
                                
                                td(:class="data.status.toLowerCase()==='stop' ? 'action' : 'text-center'")
                                    .flex.items-center.justify-end
                                        template(v-if="data.status.toLowerCase()==='stop'")
                                            router-link.btn-icon-40.m-hide(
                                                v-if="checkFeatureByAcl('territory_virtual', 'edit')"
                                                :to="'/global-territory-virtual/'+data.id+'/edit'"
                                                v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit")
                                            button.btn-icon-40.m-hide(
                                                v-if="checkFeatureByAcl('territory_virtual', 'delete')"
                                                @click="deleteData(data.id, data.name)"
                                                v-tooltip="'Delete'"
                                            )
                                                IconSet(type="delete")
                                        template(v-else)
                                            button.btn-icon-40.btn-disabled.m-hide(
                                                v-if="checkFeatureByAcl('territory_virtual', 'edit')"
                                            )
                                                IconSet(type="edit")
                                            button.btn-icon-40.btn-disabled.m-hide(
                                                v-if="checkFeatureByAcl('territory_virtual', 'edit')"
                                            )
                                                IconSet(type="delete")

                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
                        
            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                                )

    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{tmpTerritoryName}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4(v-if="checkFeatureByAcl('territory_virtual', 'create')")
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="dataNotes && dataNotes.length > 0" v-for="note in dataNotes")
                        p.mb-1 {{note.content}}
                        small.text-xs.opacity-75 {{note.created_at?note.created_at:'-'}} • added by {{note.created_by?note.created_by.name:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (dataNotes && dataNotes.length > 0)")
                            small.text-xs.opacity-75(v-if="!isLoadingNext") {{dataNotes.length}} of {{totalNotes}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="isLoadingNext")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="pageNotes<totalPageNotes")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More
                        //- tr(v-for="item in data")
                        //-     td: routerLink(to="/global-territory-virtual/detail").link {{ item.name }}
                        //-     td
                        //-         .list-target
                        //-             .list-target__avatar
                        //-                 figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="'target name'")
                        //-                     img.w-full.h-full(src="https://randomuser.me/api/portraits/women/78.jpg")
                        //-             .list-target__avatar
                        //-                 figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="'target name'")
                        //-                     img.w-full.h-full(src="https://randomuser.me/api/portraits/women/18.jpg")
                        //-             .list-target__avatar
                        //-                 figure.w-10.h-10.rounded-full.overflow-hidden(v-tooltip="'target name'")
                        //-                     img.w-full.h-full(src="https://randomuser.me/api/portraits/men/8.jpg")

                        //-     td
                        //-         p.truncate.text-sm(style="max-width: 250px;") {{ item.desc }}
                        //-     td {{ item.search_by }}
                        //-     td {{ item.created_at }}
                        //-     td: a(href="https://goo.gl/maps/hybDVWRXKG6kg1DHA" target="_blank").link.text-sm Direct to Location

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';

export default {
    name: 'territory-virtual-index',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },

    data() {
        return {
            tableThead: [
                {
                    name: 'Territory Name',
                },{
                    name: 'Target Found',
                },{
                    name: 'Case Name',
                },{
                    name: 'Desc',
                },{
                    name: 'Latest Note',
                },{
                    name: 'Search by',
                },{
                    name: 'Created At',
                },{
                    name: '',
                    action: true,
                },
            ],
            openPopUp: false,
            popUpWatchlist: false,
            dateRange: {
                startDate: moment().subtract(6, 'days').toDate(),
                endDate: moment().toDate(),
            },

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            openNote: false,
            tmpTerritoryId: null,
            tmpTerritoryName: null,            
            tmpNote: null,

            dataNotes: [],
            pageNotes: 0,
            totalPageNotes: 1,
            limitNotes: 10,
            totalNotes: 0,

            // ImgAvatar: require('../../assets/images/default-avatar.png'),
            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        }
    },

    computed: {
        ...mapState('auth', ['user']),
        ...mapState('territoryVirtual', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',            
        ]),
        route_name() {
            return this.$route.name;
        },
    },

    watch: {
        route_name(newData, oldData) {
            if (newData === 'territory-virtual-index') {
                if (oldData !== 'territory-virtual-index') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('territoryVirtual/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        keyword() {},
        notes_list(newData){
            // console.log('newData')
            // console.log(newData)
            if (Object.keys(newData).length > 0) {
                // console.log('masuk')
                this.dataNotes = this.dataNotes.concat(newData.data);
                this.totalNotes = parseInt(newData.total_data);
                this.limitNotes = parseInt(newData.per_page);
                this.pageNotes = parseInt(newData.current_page);
                this.totalPageNotes = parseInt(newData.total_page);
            } else {
                this.setDefaulDataNotes()
                // console.log('else')
                // this.dataNotes = [];
                // this.totalNotes = 0;
                // this.limitNotes = 10;
                // this.pageNotes=0;
                // this.totalPageNotes=1;
            }
            this.isLoadingNext=false
        },
    },

    methods: {
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        getData() {
            console.log(this.route_name)
            if (this.route_name === 'territory-virtual-index') {

                this.$store.dispatch('territoryVirtual/getList', [this.items_per_page, (this.page + 1), this.keyword, null, this.from_date, this.to_date]);
            }
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        monitoringAction(id, territory_name, status=1,) {
            // console.log(this.monitoringTarget.length)
            var confirm = true
            var title = 'Are you sure you want to start monitoring ?'
            if (status !== 1) {
                title = 'Are you sure you want to stop monitoring?'
            }

            if(confirm) {
                this.$swal.fire({
                    icon: 'warning',
                    title: title,
                    text: 'Territory Name : ' + territory_name,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    // console.log('on result');
                    if (result.isConfirmed) {

                        EventEmit.$emit('showLoading', true);

                        this.$store.dispatch('territoryVirtual/updateStatus', [id, status])
                        .then((resp) => {
                            // console.log(resp);
                            if (resp.result === 'success') {
                                this.page = 0;

                                this.getData();

                                this.$swal.fire({
                                    icon: 'success',
                                    title: resp.data.message,
                                    timer: 3000,
                                });
                            } else {
                                if (resp.data.message) {
                                    EventEmit.$emit('error', resp.data.message);
                                } else {
                                    EventEmit.$emit('error');
                                }
                            }
                        });
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Territory Virtual : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('territoryVirtual/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });                            
                            this.page = 0;

                            this.getData();

                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        pageChange(page) {
            this.page = page;
        },
        rageChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('territoryVirtual/setList', {});
                self.getData();
            }, 650);
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            }
        }, 
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            
            this.getData();
        }, 
        getNotes(open=true, territory_id=null,territory_name=null) {
            this.$store.commit('territoryVirtual/setNotesList', {});
            if(open){
                this.tmpTerritoryId = territory_id
                this.tmpTerritoryName = territory_name
                this.$store.dispatch('territoryVirtual/getNotesList', [this.tmpTerritoryId, this.limitNotes, this.pageNotes+1]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes()
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            this.isLoadingNext=true
            this.$store.dispatch('territoryVirtual/getNotesList', [this.tmpTerritoryId, this.limitNotes, this.pageNotes+1]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('territoryVirtual/createNotes', [this.tmpTerritoryId, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.tmpTerritoryId, this.tmpTerritoryName);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        // console.log('error')
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.tmpTerritoryId = null
            this.tmpTerritoryName = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            this.dataNotes = [];
            this.totalNotes = 0;
            this.limitNotes = 10;
            this.pageNotes=0;
            this.totalPageNotes=1;
        },
        toString(array) {
            if (array && array.length > 0) {            
                return Object.keys(array).map(function(k){
                    return array[k].operation_name
                }).join(", ")
            } else {
                return '-'
            }
        },        
        encrypt(string) {
            return encrypt(string);
        },
    },
    created() {
        this.getData();
    },
    // mounted() {
    //     this.getData(); 
    // },


    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
}
</script>
